import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Gallery from '../components/gallery';

import thumb01 from '../assets/images/thumbs/01.jpg';
import thumb02 from '../assets/images/thumbs/02.jpg';
import thumb03 from '../assets/images/thumbs/03.jpg';
import thumb04 from '../assets/images/thumbs/04.jpg';

import full01 from '../assets/images/fulls/01.jpg';
import full02 from '../assets/images/fulls/02.jpg';
import full03 from '../assets/images/fulls/03.jpg';
import full04 from '../assets/images/fulls/04.jpg';

const DEFAULT_IMAGES = [
  {
    id: '1',
    src: full01,
    thumbnail: thumb01,
    caption: "Spencer's at the Waterfront",
    description: "Spencer's is a restaurant located in Burlington, Ontario"
  },
  {
    id: '2',
    src: full02,
    thumbnail: thumb02,
    caption: 'Sunset on the mountain',
    description: 'A cold night at Sam Lawrence Park in Hamilton, Ontario'
  },
  {
    id: '3',
    src: full03,
    thumbnail: thumb03,
    caption: 'Nathan Philips Square @ night',
    description: 'Taken in Toronto, Ontario'
  },
  {
    id: '4',
    src: full04,
    thumbnail: thumb04,
    caption: 'What bird is this?',
    description: 'Taken in Stratford, Ontario'
  }
];

class HomeIndex extends React.Component {
  constructor() {
    super();

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
  }

  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  handleClickImage() {
    if (this.state.currentImage === this.props.images.length - 1) return;

    this.gotoNext();
  }

  render() {
    const siteTitle = 'Justin Kwan Lee';
    const siteDescription = 'A developer in Toronto';

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="one">
            <header className="major">
              <h2>
                <Link to={'/articles/new-site-new-tech-new-content'}>
                  New site, new tech and new content
                </Link>
              </h2>
            </header>
            <p>
              I decided to create a new site for myself using GatsbyJS to
              replace the existing one I had which was 10 years old and built in
              Wordpress. Find out some of the reasons for the move and the
              technology I used.
            </p>
            <Link to={'/articles/new-site-new-tech-new-content'}>
              Read More
            </Link>
          </section>

          <section id="two">
            <h2>Photos</h2>

            <Gallery images={DEFAULT_IMAGES} />

            <ul className="actions">
              <li>
                <a
                  href="https://www.flickr.com/photos/justinkwanlee/"
                  className="button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Full Portfolio
                </a>
              </li>
            </ul>
          </section>
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;
